/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type SEOParams = {
  canonical?: string
  description?: string
  lang?: string
  link?: any[]
  location: Location
  meta?: any[]
  ogp?: string
  title: string
  titleTemplate?: string
}

const SEO: React.FC<SEOParams> = ({
  canonical,
  description = '',
  lang = 'ja',
  link = [],
  location,
  meta = [],
  ogp = '',
  title,
  titleTemplate,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          url
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description
  const template =
    typeof titleTemplate === 'undefined'
      ? `%s | ${site.siteMetadata.title}`
      : titleTemplate
  const siteUrl =
    canonical || new URL(location.pathname, site.siteMetadata.url).href

  const ogpUrl = ogp || new URL('/images/ogp.png', site.siteMetadata.url).href

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={template}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: siteUrl,
        },
        {
          property: 'og:image',
          content: ogpUrl,
        },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: ogpUrl,
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black-translucent',
        },
      ].concat(meta)}
      link={[
        {
          rel: 'canonical',
          content: siteUrl,
        },
      ].concat(link)}
    />
  )
}

export default SEO
